// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Application
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { NoAuthGuard } from 'src/app/guards/no-auth/no-auth.guard';

const routes: Routes = [
  {
    path: 'clinician',
    canActivate: [AuthGuard],
    data: {
      expectedRole: 'clinician',
      allowedRoles: ['admin', 'clinician'],
    },
    loadChildren: () =>
      import('./clinician/clinician.module').then(
        (m) => m.ClinicianModule,
      ),
  },
  {
    path: '',
    canActivate: [NoAuthGuard],
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule,
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
