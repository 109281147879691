// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Auth 0
import { JwtHelperService } from '@auth0/angular-jwt';

// RxJS
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

// Application
import { baseUrl } from 'src/environments/environment';
import { LoginAttempt } from 'src/app/types/login-attempt';
import { LoginFailureResponse } from 'src/app/types/login-response';
import { User } from 'src/app/types/user';

// const baseUrl = 'http://localhost:3000/v1';
//const baseUrl = 'https://pecss-test.cloud.rnoc.gatech.edu/v1';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private http: HttpClient, private router: Router) {}

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    return !this.jwtHelper.isTokenExpired(token);
  }

  login(body: LoginAttempt): Observable<unknown> {
    console.log('LoginAttempt body');
    console.log(body);
    return this.http
      .post<unknown>(baseUrl + '/auth/login', body)
      .pipe(catchError(this.handleError('login')));
  }

  logout(): void {
    localStorage.clear();
  }

  routeUser(): void {
    const currentUser: User = this.jwtHelper.decodeToken(
      localStorage.getItem('token'),
    );
    if (currentUser.role === 'clinician' || currentUser.role === 'admin') {
      this.router.navigateByUrl('/clinician');
    } else {
      localStorage.clear();
      this.router.navigateByUrl('/page-not-found');
    }
  }

  private handleError(operation: string) {
    return (res: HttpErrorResponse): Observable<LoginFailureResponse> => {
      console.log(`${operation} failed: ${res.message}`);
      return of({ message: res.error.message });
    };
  }
}
