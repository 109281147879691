// Angular
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

// RxJS
import { Observable } from 'rxjs';

// Application
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/types/user';

/**
 * Guard that protects routes from being activated without proper authentication
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Injects necessary services enabling the auth guard to function
   * @param auth - Service that contains core authentication functionality
   * @param router - Service for navigating between routes in the app
   */
  constructor(private auth: AuthService, private router: Router) {}

  /**
   * Decides if a route can be activated based on:
   *   - Whether the user is authenticated
   *   - Whether the user has permission to access the route
   * @param next - The route trying to be accessed
   */
  canActivate(
    next: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const expectedRole = next.data.expectedRole;
    const allowedRoles = next.data.allowedRoles;
    const currentUser: User = this.auth.jwtHelper.decodeToken(
      localStorage.getItem('token'),
    );

    if (this.auth.isAuthenticated() && (currentUser.role === expectedRole ||
      (allowedRoles && allowedRoles.includes(currentUser.role)))) {
      return true;
    }
    this.router.navigateByUrl('/login');
    return false;
  }
}
