// Angular
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';

// RxJS
import { Observable } from 'rxjs';

// Application
import { AuthService } from 'src/app/services/auth/auth.service';

/**
 * Guard that protects routes from being activated if the user is already authenticated
 */
@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  /**
   * Injects necessary services enabling the no auth guard to function
   * @param auth - Service that contains core authentication functionality
   */
  constructor(private auth: AuthService) {}

  /**
   * Decides if a route can be activated based on whether the user is already authenticated
   */
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isAuthenticated()) {
      this.auth.routeUser();
      return false;
    }
    return true;
  }
}
